import * as TYPES from '@/store/types'
import RegistrationProvider from '@provider/registrations'

import { createZonedDateFromUtcDate, isSameWeek } from '@/helpers/dates/index.js';

// initial state
const state = {
  filters: {
    dateFrom: undefined,
    dateTill: undefined,
    userIds: undefined,
    projectIds: undefined,
    typeIds: undefined,
  },
  registrations: [],
}

// getters
const getters = {
  registrations: state => ({ dateFrom, dateTill, projectId } = {}) => {
    const dateFromInUtc = createZonedDateFromUtcDate(dateFrom);
    const dateTillInUtc = createZonedDateFromUtcDate(dateTill);

    return state.registrations.filter(e => {
      if (projectId) return e.project && e.project.id == projectId

      return dateFrom && dateTill
        ? e.dateMoment.isSameOrAfter(dateFromInUtc) &&
            e.dateMoment.isSameOrBefore(dateTillInUtc)
        : true
    })
  },

  hoursRegistrationsByProjectId: state => {
    const { registrations } = state;

    const hoursRegistrationsByProjectId = registrations.reduce((hashTable, registration) => {
      const { project, registrationType } = registration;

      if (project?.id && registrationType.isHoursProject) {
        hashTable[project.id] = (hashTable[project.id] ?? []);
        hashTable[project.id].push(registration);
      }

      return hashTable;
    }, {});

    return hoursRegistrationsByProjectId;
  },

  registrationsHoursProject: state => ({
    dateFrom,
    dateTill,
    projectId,
  } = {}) =>
    state.registrations.filter(e => {
      if (!e.registrationType.isHoursProject) return false

      if (projectId) return e.project && e.project.id == projectId

      return isSameWeek({ date: e.dateMoment, dateFrom, dateTill });
    }),

  registrationsMaterials: state => ({ dateFrom, dateTill, projectId } = {}) =>
    state.registrations.filter(e => {
      if (!e.registrationType.isMaterial) return false

      if (projectId) return e.project && e.project.id == projectId

      return isSameWeek({ date: e.dateMoment, dateFrom, dateTill });
    }),

  registrationsProjectHoursSumm: state => ({ projectId } = {}) =>
    state.registrations
      .filter(
        e =>
          e.registrationType.isHoursProject &&
          e.project &&
          e.project.id == projectId
      )
      .map(e => e.registrationType.price * e.amount)
      .reduce((a, b) => a + b, 0),

  registrationsMaterialSumm: state => ({ projectId } = {}) =>
    state.registrations
      .filter(
        e =>
          e.registrationType.isMaterial &&
          e.project &&
          e.project.id == projectId
      )
      .map(e => e.registrationType.price * e.amount)
      .reduce((a, b) => a + b, 0),

  matchRegistrationWithFilters:
    state =>
    (registration, filters = state.filters) => {
      if (!filters) true;

      const { dateFrom, dateTill, userIds, projectIds, typeIds } = filters;

      if (dateFrom) {
        const dateFromInUtc = createZonedDateFromUtcDate(dateFrom);
        if (!registration.dateMoment.isSameOrAfter(dateFromInUtc)) return false;
      }

      if (dateTill) {
        const dateTillInUtc = createZonedDateFromUtcDate(dateTill);
        if (!registration.dateMoment.isSameOrBefore(dateTillInUtc)) return false;
      }

      if (projectIds?.length) {
        if (!registration.project || !projectIds.includes(registration.project.id)) return false;
      }

      if (userIds?.length) {
        if (!registration.user || !userIds.includes(registration.user.id)) return false;
      }

      if (typeIds?.length) {
        const oldRegistration = state.registrations.find(r => r.id === registration.id);

        if (!registration.registrationType) return false;

        /**
         * we assume that if the type does not match, but the registration is stored in the store,
         * then the registration matches the replaced registration type
         */
        if (
          !typeIds.includes(registration.registrationType.id) &&
          !(
            oldRegistration &&
            oldRegistration.registrationType &&
            oldRegistration.registrationType.id === registration.registrationType.id
          )
        )
          return false;
      }

      return true;
    },
}

// actions
const actions = {
  addRegistration({ commit, getters }, form) {
    return new Promise((resolve, reject) => {
      RegistrationProvider.addRegistration(form)
        .then(registration => {
          if (getters.matchRegistrationWithFilters(registration)) {
            commit(TYPES.REGISTRATIONS.INIT_REGISTRATIONS, registration)
          }
          resolve(registration)
        })
        .catch(reject)
    })
  },

  getRegistrations({ commit }, form) {
    return new Promise((resolve, reject) => {
      RegistrationProvider.getRegistrations(form)
        .then(registrations => {
          if (registrations?.length) {
            commit(TYPES.REGISTRATIONS.SET_REGISTRATIONS, registrations)
          }
          commit(TYPES.REGISTRATIONS.SET_FILTERS, form);
          resolve(registrations)
        })
        .catch(reject)
    })
  },

  getRegistration({ commit, getters }, { id }) {
    return new Promise((resolve, reject) => {
      RegistrationProvider.getRegistration({ id })
        .then(registration => {
          if (getters.matchRegistrationWithFilters(registration)) {
            commit(TYPES.REGISTRATIONS.INIT_REGISTRATIONS, registration);
          }
          resolve(registration)
        })
        .catch(reject)
    })
  },

  updateRegistration({ commit, getters }, form) {
    return new Promise((resolve, reject) => {
      RegistrationProvider.updateRegistration(form)
        .then(registration => {
          if (getters.matchRegistrationWithFilters(registration)) {
            commit(TYPES.REGISTRATIONS.INIT_REGISTRATIONS, registration)
          }
          resolve(registration)
        })
        .catch(reject)
    })
  },

  deleteRegistration({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      RegistrationProvider.deleteRegistration({ id })
        .then(() => {
          commit(TYPES.REGISTRATIONS.DELETE_REGISTRATION, id)
          resolve()
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [TYPES.REGISTRATIONS.SET_FILTERS](state, filters) {
    state.filters = filters;
  },

  [TYPES.REGISTRATIONS.SET_REGISTRATIONS](state, registrations) {
    state.registrations = registrations;
  },

  [TYPES.REGISTRATIONS.INIT_REGISTRATIONS](state, data) {
    let list = Array.isArray(data) ? data : [data],
      ids = list.map(e => e.id)

    state.registrations = [
      ...state.registrations.filter(e => !ids.includes(e.id)),
      ...list.filter(e => e.canView && !e.isDeleted),
    ]
  },

  [TYPES.REGISTRATIONS.DELETE_REGISTRATION](state, id) {
    const index = state.registrations.findIndex(e => e.id == id);
    if (index > -1) state.registrations.splice(index, 1);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
