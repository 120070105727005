import _ from 'lodash'

import Model from './Model'
import ChecklistModel from './Checklist'
import UserModel from './User'
import LabelModel from './Label'
import { utils } from '@/plugins/utils'

class ChecklistItemModel extends Model {
  constructor(data) {
    super(data)
    this.canChangeStatus = Boolean(data.can_change_status)
    this.canChangeDateUsers = Boolean(data.permissions?.can_change_date_users)
    this.canSynchronize = Boolean(data.permissions?.can_synchronize)

    this.id = Number(data.id)
    this.checklistId = Number(data.checklist_id)
    this.countComments = Number(data.count_comments)
    this.countFiles = Number(data.count_files)

    this.position = Number(data.position)
    this.projectId = data.project_id ?? null
    this.projectName = data.project_name ?? null

    // if a checklist item is created from the checklist model constructor,
    // the checklist property is missing in the data
    // TODO: find a way to transform this architectural problem
    this.checklist = data.checklist
      ? new ChecklistModel(data.checklist)
      : undefined

    this.synchronize = Boolean(data.external_calendar?.synchronize)
    this.eventId = data.external_calendar?.external_event_id
    this.eventStatus = data.external_calendar?.status
    this.emails = data.external_calendar?.emails
    this.location = data.external_calendar?.location
    this.description = data.external_calendar?.description

    this.checked = Boolean(data.checked)
    this.checkedAt = data.checked_at_utc ? this.toMomentDate(data.checked_at_utc) : null
    this.name = utils.decodeCharacterEntities(data.name || '')

    this.userIds = Model.numberArray(data.user_ids);
    this.resourceIds = Model.numberArray(data.planning_resource_ids);

    this.labels = Array.isArray(data.labels) ? data.labels.map(e => new LabelModel(e)) : []

    // TIME OUT
    this.startAt = data.start_at_utc ? this.toMomentDate(data.start_at_utc) : null
    this.endAt = data.end_at_utc ? this.toMomentDate(data.end_at_utc) : null

    this.totalFormAnswers = data.total_form_answers;
    this.totalFormFields = data.total_form_fields;

    this.chatId = Number(data.chat_id);
    this.chatName = data.chat_name;
  }
}

export default ChecklistItemModel
