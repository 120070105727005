export default {
  computed: {
    breadcrumbs() {
      let list = []

      this.getRecursiveParentFolder(this.activeFolder, list)
      if (this.accessMode) list.push(this.accessFolder)

      return this.lodash.reverse(list)
    },
  },

  methods: {
    handleClickBreadcrumbs(folder) {
      this.handleUnselectMedia()

      if (folder.id === undefined) {
        this.accessMode = false
      }

      if (this.activeFolder === folder.id) return;

      this.activeFolder = folder.id;

      if (folder.remoteId && !this.isLoadingRemoteFolder(folder.remoteId)) {
        this.getSharepointProjectContents({
          remoteFolderId: folder.remoteId,
          projectId: this.project.id,
        });
      }
    },

    getRecursiveParentFolder(folderId, arr) {
      const f = this.folders.find(e => e.id == folderId)
      if (f) {
        arr.push({ ...f })

        if (f.parentId) {
          this.getRecursiveParentFolder(f.parentId, arr)
        }
      }
    },
  },
}
