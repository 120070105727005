import * as TYPES from '@/store/types'
import ProjectCalculationsProvider from '@provider/projectCalculations'

// initial state
const state = {
  projectCalculations: [],
}

// getters
const getters = {
  gettProjectCalculations: state => projectId =>
    state.projectCalculations.filter(e => e.projectId == projectId),

  getProjectCalculationById: state => calculationId =>
    state.projectCalculations.find((calculation) => calculation.id === calculationId),
}

// actions
const actions = {
  addProjectCalculation({ commit }, { projectId, name, type, amount, formula, hasInvoice }) {
    return new Promise((resolve, reject) => {
      ProjectCalculationsProvider.addProjectCalculation({
        projectId,
        name,
        type,
        amount,
        formula,
        hasInvoice,
      })
        .then(projectCalculation => {
          commit(
            TYPES.PROJECT_CALCULATIONS.INIT_PROJECT_CALCULATIONS,
            projectCalculation
          )
          resolve(projectCalculation)
        })
        .catch(reject)
    })
  },

  getProjectCalculation({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      ProjectCalculationsProvider.getProjectCalculation({ id })
        .then(projectCalculation => {
          commit(
            TYPES.PROJECT_CALCULATIONS.INIT_PROJECT_CALCULATIONS,
            projectCalculation
          )
          resolve(projectCalculation)
        })
        .catch(reject)
    })
  },

  getProjectCalculations({ commit }, { projectId, cancelToken }) {
    return new Promise((resolve, reject) => {
      ProjectCalculationsProvider.getProjectCalculations({ projectId, cancelToken })
        .then(ProjectCalculations => {
          commit(
            TYPES.PROJECT_CALCULATIONS.INIT_PROJECT_CALCULATIONS,
            ProjectCalculations
          )
          resolve(ProjectCalculations)
        })
        .catch(reject)
    })
  },

  updateProjectCalculations({ commit }, { id, name, amount, formula, hasInvoice}) {
    return new Promise((resolve, reject) => {
      ProjectCalculationsProvider.updateProjectCalculations({
        id,
        name,
        amount,
        formula,
        hasInvoice,
      })
        .then(ProjectCalculation => {
          commit(
            TYPES.PROJECT_CALCULATIONS.INIT_PROJECT_CALCULATIONS,
            ProjectCalculation
          )
          resolve(ProjectCalculation)
        })
        .catch(reject)
    })
  },

  deleteProjectCalculations({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      ProjectCalculationsProvider.deleteProjectCalculations({ id })
        .then(() => {
          commit(TYPES.PROJECT_CALCULATIONS.DELETE_PROJECT_CALCULATION, id)
          resolve()
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [TYPES.PROJECT_CALCULATIONS.INIT_PROJECT_CALCULATIONS](state, data) {
    let list = Array.isArray(data) ? data : [data],
      ids = list.map(e => e.id)

    state.projectCalculations = [
      ...state.projectCalculations.filter(e => !ids.includes(e.id)),
      ...list.filter(e => e.canView),
    ]
  },

  [TYPES.PROJECT_CALCULATIONS.DELETE_PROJECT_CALCULATION](state, id) {
    state.projectCalculations = state.projectCalculations.filter(
      e => e.id != id
    )
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
