import _, { keyBy } from 'lodash'
import moment from 'moment';

import md5 from 'md5'
import ImageCompressor from 'compressorjs'

import { detect } from 'detect-browser'
import MobileDetect from 'mobile-detect'

import { EmojiIndex } from 'emoji-mart-vue-fast/src';
import dataEmoji from 'emoji-mart-vue-fast/data/all.json';

import emojiRegex from 'emoji-regex'
import emojiUnicode from 'emoji-unicode'

import Store from '@/store'
import Router from '@/routes'
import i18n from '@/i18n'

import helpers from '@/helpers'

/**
 * MODULES
 */

import ModDuedate from './duedate'
import ModTime from './time'
import ModSort from './sort'
import ModColors from './colors'
import ModMouse from './mouse'
import ModValidation from './validation'
import ModScheduler from './scheduler'
import ModKeys from './keys'

import ModLocalStorage from './../localStorageWrapper'
import { LINK_REGEXP_PATTERN } from '@/constants.js';

const unicodeEmojiRegex = emojiRegex()
const [unifiedKey, shortNamesKey] = dataEmoji.compressed ? ['b', 'j'] : ['unified', 'short_names'];
const EMOJIS = new Map(
  Object.values(dataEmoji.emojis).map(e => [e[unifiedKey], e[shortNamesKey][0]])
)

window.EMOJIS = EMOJIS

const replacePriceZeroDecimals = (string, suffix) => {
  const matches = string.match(/0+$/);
  const lastIndex  = matches?.length
    ? string.lastIndexOf(matches[matches.length-1])
    : -1;

  const limiterIndex = lastIndex - 1;

  return limiterIndex < 0 || !(/[,.]/.test(string[limiterIndex]))
    ? string
    : string.substring(0, lastIndex) + suffix;
}

const parser = new DOMParser();
const emptySpan = document.createElement('span');
const toHTML = string => {
  emptySpan.innerHTML = string;
  const childNodes = [...emptySpan.childNodes];
  emptySpan.innerHTML = '';
  return childNodes;
};

class Utils {
  constructor() {
    this.browser = detect()
    this.device = new MobileDetect(window.navigator.userAgent)
    this.isProduction = process.env.NODE_ENV == 'production'
    this.isApplePlatform = helpers.isApplePlatform()

    this.emojiIndex = new EmojiIndex(dataEmoji);

    // Modules
    this.duedate = ModDuedate
    this.time = ModTime
    this.$time = ModTime
    this.$sort = ModSort
    this.$colors = ModColors
    this.$mouse = ModMouse
    this.$validation = ModValidation
    this.$scheduler = ModScheduler
    this.$keys = ModKeys

    this.$localStore = ModLocalStorage
  }

  throttled(delay, fn) {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  }

  chunkArr(collection, size) {
    let result = []

    size = parseInt(size) || 2

    for (let x = 0; x < Math.ceil(collection.length / size); x++) {
      let start = x * size,
        end = start + size

      result.push(collection.slice(start, end))
    }

    return result
  }

  openProject(project, target) {
    const canViewInfo = _.get(
      Store,
      'state.User.permissions.project.canViewInfo',
      false
    )

    let route = null;

    if (canViewInfo && !project.countNewMessages) {
      route = {
        name: 'projectInfo',
        params: {
          projectId: project.id,
        },
      };
    } else if (project.firstProjectChatId) {
      route = {
        name: 'projectChat',
        params: {
          projectId: project.id,
          chatId: project.firstProjectChatId,
        },
      };
    } else {
      route = {
        name: 'chats',
        params: {
          projectId: project.id,
        },
      };
    }

    this.openRouterLink(route, target);
  }

  openRouterLink(route, target) {
    if (target) {
      const { href } = Router.resolve(route);
      this.openLink(href, target);
    } else {
      Router.push(route);
    }
  }

  getMessageLastText(msg, lastMessageUserFirstName = '') {
    let res = { action: null, text: null }

    try {
      const userFirstName = msg.user
        ? msg.user.firstName
        : lastMessageUserFirstName

      if (msg.isJoin || msg.isLeave) {
        if (msg.isJoin) {
          res.action = `${userFirstName} ${i18n.global.t('messenger.joinUser')}`
        } else if (msg.userId == msg.user.id) {
          res.action = `${userFirstName} ${i18n.global.t('messenger.leaveUser')}`
        } else {
          res.action = `${userFirstName} ${i18n.global.t('messenger.removedUser')}`
        }
      } else {
        res.text = `${userFirstName}: ${
          msg.isMedia ? msg.media.fileName : msg.text
        }`
      }
    } catch (error) {
      debug(`[-] ${error}`, this)
    }

    return res
  }

  formatStrToNum(val) {
    if (val == null || val == 0) return null
    val = val === '-' ? (val += '0') : val

    return Number(val.replace(/\./g, '').replace(',', '.'))
  }

  formatNumToStr(val) {
    if (val == null || val == 0) return null
    return Number(val)
      .toFixed(2)
      .replace('.', ',')
  }

  formatNumToPrice(
    val,
    {
      max = Number.MAX_SAFE_INTEGER,
      replaceZeroDecimals = false
    } = {}
  ) {
    if (val == null) return null
    if (Math.abs(val) > max) {
      val = val > 0 ? max : max * -1
    }

    const price = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    })
      .format(val)
      .replace(/€\s+/, '')

    return replaceZeroDecimals
      ? replacePriceZeroDecimals(price, '-')
      : price;
  }

  formatNumWithComma(num, fixed = 2) {
    return String(parseFloat(num.toFixed(fixed))).replace('.', ',')
  }

  clearEmptyLines(text) {
    return (text.split?.(/\n/) || [])
      .map(line => line.trim())
      .filter(line => Boolean(line))
      .join('\n');
  }

  getShortNameEmojiFromNative(unicode) {
    return EMOJIS.get(unicode.toUpperCase())
  }

  textEmojiNativeToShortName(text = '') {
    return text.replace(unicodeEmojiRegex, (match, offset) => {
      let result = this.getShortNameEmojiFromNative(
        emojiUnicode(match).replace(' ', '-')
      )
      return result ? `:${result}:` : match
    })
  }

  replaceEmojiNativeToTags(text = '') {
    return text.replace(unicodeEmojiRegex, (match, offset) => {
      let result = this.getShortNameEmojiFromNative(
        emojiUnicode(match).replace(' ', '-')
      )
      return result ? this.genEmoji(result) : match
    })
  }

  setDataWithCursor({ data, setter, el, callback }) {
    const pos = el.selectionStart

    callback(data.slice(0, pos) + setter + data.slice(pos, data.length))

    setTimeout(() => {
      el.blur()
      el.setSelectionRange(pos + setter.length, pos + setter.length)
      el.dispatchEvent(new Event('refresh'))
      el.focus()
    }, 50)
  }

  setFavicon(name) {
    let link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = `${process.env.BASE_URL}${name}`
    document.getElementsByTagName('head')[0].appendChild(link)
  }

  genClientMsgId(userId, chatId) {
    return md5(`${new Date().getTime()}${userId}${chatId}`)
  }

  genUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )
  }

  getExtention(name) {
    return /[.]/.exec(name) ? /[^.]+$/.exec(name)[0] : '?'
  }

  /*
   * @return Array[String]
   */
  response(obj, errors = []) {
    if (obj instanceof Object) {
      for (let key in obj) {
        errors = this.response(obj[key], errors)
      }
    } else if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        errors = this.response(obj[i], errors)
      }
    } else if (Number(obj) || typeof obj === 'string') {
      const l = i18n.global.t('responses.' + obj)

      // TODO: CHECK LOCALE MESSAGE IS EXIST
      if (l && !l.includes('responses.')) {
        errors.push(l)
      }
    }

    return errors
  }

  formatTimeFromNow(date) {
    const currentTime = moment(),
      time = date ? moment(date) : null

    return !time
      ? ''
      : currentTime.diff(time, 'days') === 1
      ? i18n.global.t('time.yesterday')
      : currentTime.diff(time, 'days') <= 7 &&
        currentTime.diff(time, 'days') >= 1
      ? time.format('dddd')
      : currentTime.diff(time, 'days') > 7
      ? time.format('DD-MM-YY')
      : time.format('HH:mm')
  }

  clearTags(str) {
    return str
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
  }

  clearTagsInValues(data) {
    if (_.isPlainObject(data)) {
      return Object.fromEntries(
        Object.entries(data).map(
          ([key, value]) => [key, this.clearTagsInValues(value)],
        ),
      );
    }
    if (Array.isArray(data)) {
      return data.map(this.clearTagsInValues);
    }
    if (_.isNumber(data) || _.isBoolean(data) || _.isNull(data)) {
      return data
    }
    return this.clearTags(data);
  }

  replaceLinks(str) {
    let urlRegex = new RegExp(LINK_REGEXP_PATTERN, 'gi');
    return str.replace(urlRegex, url => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  }

  /**
   * Wrap links in the `a` tag and don't touch existent `a` tags
   * @param {string} string
   * @returns {string}
   */
  replaceLinksUsingDOMParser(string) {
    const htmlDocument = parser.parseFromString(string, 'text/html');
    const stack = [...htmlDocument.body.childNodes];

    while (stack.length) {
      const node = stack.shift();

      if (node.nodeType === Element.TEXT_NODE) {
        const link = this.replaceLinks(node.textContent);
        node.replaceWith(...toHTML(link));
      } else if (node.nodeName === 'A') {
        node.target = '_blank';
        node.rel = 'noopener noreferrer';
      } else if (node.childNodes?.length) {
        stack.push(...node.childNodes);
      }
    }

    return htmlDocument.body.innerHTML;
  }

  decodeCharacterEntities(string) {
    const htmlDocument = parser.parseFromString(string, 'text/html');
    return htmlDocument.body.textContent;
  }

  replaceUserIdTagsWithUserNameTags(str, { wrap = false, users = [] } = {}) {
    const userMap = keyBy(users, "id");
    const userRegexp = /@userid:(\d+)/gi;
    let replacedStr = typeof str === 'string' ? str : '';
  
    replacedStr = replacedStr.replace(userRegexp, (match, userId) => {
      const user = userMap[userId];
      return user
        ? wrap
          ? `<span class="text-green font-bold">@${user.firstName}</span>`
          : `@${user.firstName}`
        : match;
    });
  
    return replacedStr;
  }

  removeTags(htmlString) {
    let cleanString = htmlString.replace(/<\/?[^>]+(>|$)/g, ' ');

    cleanString = cleanString.replace(/\s+/g, ' ').trim();

    return cleanString;
  }

  replaceEmoji(str) {
    if (typeof str !== 'string') {
      return ''
    }

    let replaces = str.match(/:(.\d):|:(\w+-?\w+):/gi) || []

    replaces.forEach(emoji => {
      str = str.replace(emoji, this.genEmoji(emoji.replace(/:/gi, '')))
    })

    return str
  }

  genEmoji(name) {
    return `<emoji emoji="${name}" :data="$utils.emojiIndex" :size="16" :native="true"/>`
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, {
      type: mime,
    })
  }

  createForm(data) {
    let formData = new FormData()

    for (let key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach(dataItem => {
          formData.append(`${key}[]`, dataItem);
        });
      } else if (data[key]) {
        formData.append(key, data[key])
      }
    }

    return formData
  }

  checkHeicFile(blob) {
    const extensionsRegexp = /^(image\/(heic|heif))$/i
    return extensionsRegexp.test(blob.type)
  }

  async convertHeicToJpegFile(heicFile) {
    const {
      default: heic2any,
    } = await import(/* webpackChunkName: 'heic-converter' */ 'heic2any');

    const jpegBlob = await heic2any({
      blob: heicFile,
      toType: 'image/jpeg',
      quality: 1,
    });

    const filename = heicFile.name.replace?.(/(\.heic|\.heif)$/i, '.jpeg');
    const type = 'image/jpeg';
    const jpegFile = new File([jpegBlob], filename, { type });
    return jpegFile;
  }

  async prepareFileForUpload({
    file,
    extension = null,
  }) {
    const isHeicFile = /^hei(c|f)$/i.test(extension) || this.checkHeicFile(file);

    let fileForScaling = file;

    if (isHeicFile) {
      try {
        fileForScaling = await this.convertHeicToJpegFile(file)
      } catch {
        console.error(
          'Failed to convert an image to JPEG format. The original file was used as a fallback.'
        );
      }
    }

    try {
      const preparedFile = await this.scaleImage(fileForScaling);
      return preparedFile;
    } catch {
      console.error(
        `Failed to scale an image with the '${extension}' extension. The original file was used as a fallback.`
      );
      return file;
    }
  }

  scaleImage(file) {
    // return file
    let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i

    if (file.size / 1024 / 1024 < 2) {
      return file
    }

    return new Promise((resolve, reject) => {
      if (allowedExtensions.exec(file.name)) {
        new ImageCompressor(file, {
          quality: 0.6,
          maxHeight: 2000,
          maxWidth: 2000,

          success(scaledFile) {
            resolve(scaledFile)
          },
          error(err) {
            reject(err)
          },
        })
      } else {
        resolve(file)
      }
    })
  }

  openLink(link, target) {
    window.open(link, target);
  }

  openEmail(email) {
    window.open(`mailto:${email}`);
  }

  openPhone(phone) {
    window.open(`tel:${phone}`)
  }

  openLocation(location) {
    window.open(
      process.env.VUE_APP_GOOGLE_MAPS_SEARCH.replace(
        '{input}',
        location
      )
    )
  }

  defineNonReactive(value) {
    if (_.isObjectLike(value)) {
      return Object.freeze(value);
    }

    return value;
  }

  createThemeStyles(company) {
    const hex = (value) => `#${value}`;
    const url = (value) => `url(${value})`;
    const gradient = (value) => `linear-gradient(to right, transparent 0%, ${hex(value)} 15%, transparent 30%)`;

    const {
      customProjectsLogo,
      customColorHoverColor,
      customColorLogoProjects,
      customColorTeamchatsColor,
      customColorProjectsChatsSearch,
    } = company || {};

    const rules = {
      ".user.layout > .nav-panel": {
        "background-color": [customColorLogoProjects, hex]
      },

      ".user.layout > .nav-panel > .header .logo": {
        "background-image": [customProjectsLogo, url]
      },

      ".user.layout > .nav-panel > .body > .teamchats": {
        "background-color": [customColorTeamchatsColor, hex]
      },

      [`
        .user.layout > .nav-panel > .body > .teamchats > .search,
        .user.layout > .nav-panel > .body > .projects > .search
      `]: {
        "background-color": [customColorProjectsChatsSearch, hex]
      },

      [`
        .user.layout > .nav-panel > .body > .projects > .title:not(.disabled):hover,
        .user.layout > .nav-panel > .body > .projects > .list .item:hover,
        .user.layout > .nav-panel > .body > .teamchats > .list .item:hover
      `]: {
        "background-color": [customColorHoverColor, hex]
      },

      ".user.layout > .nav-panel .vue-content-placeholders .vue-content-placeholders-text__line": {
        "background-color": [customColorProjectsChatsSearch, hex]
      },

      ".user.layout > .nav-panel .vue-content-placeholders .vue-content-placeholders-text__line:before": {
        "background": [customColorHoverColor, gradient]
      },
    };

    const styles = Object.entries(rules).reduce((resultRules, [selector, properties]) => {
      const propertyValues = Object.entries(properties).reduce((resultPropertyValues, [property, value]) => {
        let transformer = null;

        // normalize value
        if (Array.isArray(value)) {
          transformer = value[1];
          value = value[0];
        }

        if (value !== null && value !== undefined) {
          const propertyValue = transformer ? transformer(value) : value;

          resultPropertyValues.push(`${property}: ${propertyValue};`);
        }

        return resultPropertyValues;
      }, []);

      if (propertyValues.length) {
        resultRules.push(`${selector} {${propertyValues.join('')}}`);
      }

      return resultRules;
    }, []);

    return styles.join('\n');
  }

  getChangedFieldsFromSelectedContact(contact, oldContact) {
    const updatedFields = {};

    Object.entries({
      name: contact.contactPerson,
      phone: contact.contactPersonPhone,
      email: contact.contactPersonEmail,
      address: contact.location,
    }).forEach(([field, value]) => {
      if (!oldContact || value && value !== oldContact[field]) {
        updatedFields[field] = value;
      }
    })

    const hasChanges = Boolean(Object.values(updatedFields).length);

    return hasChanges ? updatedFields : null;
  }

  addBreaksIfTextIsNotEmpty (text, count = 1) {
    const breaks = Array.from({ length: count }, () => '<br />').join('');
    return text ? `${text}${breaks}` : text;
  }
}

export const utils = new Utils()

export default {
  install(app) {
    app.config.globalProperties.$utils = utils;
  },
}
