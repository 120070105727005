import _ from 'lodash'

import downloadjs from 'downloadjs'
import Provider from '@provider'
import { ChecklistModel } from '@/models'
import helpers from '@/helpers'
import LocalStorageWrapper from '@/plugins/localStorageWrapper';
import { LOCAL_STORAGE_KEYS } from '@/constants'

function getChecklistsExpandedState() {
  return LocalStorageWrapper.getItem(LOCAL_STORAGE_KEYS.CHECKLISTS, { deff: {}, parse: true }) || {};
}

function setChecklistsExpandedState(checklistsExpandedState, userId) {
  let allChecklistsInfo = getChecklistsExpandedState(userId);

  LocalStorageWrapper.setItem(LOCAL_STORAGE_KEYS.CHECKLISTS, Object.assign(allChecklistsInfo, {
    [userId]: checklistsExpandedState
  }));
}

function getUserChecklistsExpandedState(userId) {
  return getChecklistsExpandedState()?.[userId] ?? {};
}

export default {
  getChecklistsLastUsed: ({ cancelToken }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get('/checklists', { cancelToken })
        .then(response =>
          resolve(response.data.map(e => new ChecklistModel(e)))
        )
        .catch(reject)
    })
  },

  cloneChecklist: ({ checklistId, projectId, chatId, name }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/checklists/${checklistId}/clone`, {
          chat_id: chatId,
          project_id: projectId,
          name,
        })
        .then(response => resolve(new ChecklistModel(response.data)))
        .catch(reject)
    })
  },

  addChecklist: ({ name, projectId, chatId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post('/checklists', {
          name,
          chat_id: chatId,
          project_id: projectId,
        })
        .then(response => resolve(new ChecklistModel(response.data)))
        .catch(reject)
    })
  },

  getChecklist: ({ id }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/checklists/${id}`)
        .then(response => resolve(new ChecklistModel(response.data)))
        .catch(reject)
    })
  },

  downloadChecklistFiles: ({
    id,
    fileName = 'archive.zip',
    progress = () => {},
  }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(
          `/checklists/${id}/download_files_zip`,
          {},
          {
            responseType: 'blob',
            onDownloadProgress: progressEvent => {
              let progressPercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              progress(
                progressPercent < 100 ? progressPercent : 0,
                progressEvent
              )
            },
          }
        )
        .then(response => {
          const status = _.get(response, 'status', 204)
          if (status == 204) {
            // To do nothing
          } else {
            downloadjs(
              response.data,
              fileName,
              response.headers['content-type']
            )
          }

          resolve()
        })
        .catch(reject)
    })
  },

  exportChecklist: ({
    fileName = 'checklist.pdf',
    id,
    comments = false,
    files = false,
    logs = false,
    form = false,
    registrationHours,
    registrationMaterial,
    registrationNotes,
    imageSize,
  }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(
          `/checklists/${id}/export`,
          {
            comments: +comments,
            files: +files,
            logs: +logs,
            form: +form,
            registration_hours: +registrationHours,
            registration_material: +registrationMaterial,
            registration_notes: +registrationNotes,
            image_size: imageSize,
          },
          {
            responseType: 'blob',
          }
        )
        .then(response => {
          downloadjs(response.data, fileName, response.headers['content-type'])
          resolve()
        })
        .catch(reject)
    })
  },

  updateChecklistAccess: ({ id, access }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/checklists/${id}`, {
          access: +access,
        })
        .then(response => resolve(new ChecklistModel(response.data)))
        .catch(reject)
    })
  },

  updateChecklistCover: ({ id, file }) => {
    const form = helpers.createForm({ cover_image: file });

    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/checklists/${id}/cover_image`, form)
        .then(response => resolve(new ChecklistModel(response.data)))
        .catch(reject)
    })
  },

  updateChecklistFavourite: ({ id, favourite }) => {
    return Provider.api
      .put(`/checklists/${id}`, {
        favourite: Number(favourite),
      })
      .then(response => new ChecklistModel(response.data))
  },

  updateChecklistExpandedState: ({ checklistId, value }) => {
    return Provider.api
      .put(`/checklists/${checklistId}`, {
        open_close_state: Number(value),
      })
      .then(response => new ChecklistModel(response.data))
  },

  updateChecklist: ({ id, name, position, chatId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/checklists/${id}`, {
          name,
          position,
          chat_id: chatId,
        })
        .then(response => resolve(new ChecklistModel(response.data)))
        .catch(reject)
    })
  },

  deleteChecklist: ({ id }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/checklists/${id}`)
        .then(resolve)
        .catch(reject)
    })
  },

  getChecklistExpandedState: ({ checklistId, userId }) => {
    const expandedState = getUserChecklistsExpandedState(userId);
    return expandedState && checklistId in expandedState ? expandedState[checklistId] : true;
  },

  setChecklistExpandedState: ({ checklistId, value, userId }) => {
    const expandedState = getUserChecklistsExpandedState(userId);
    expandedState[checklistId] = value;
    setChecklistsExpandedState(expandedState, userId);
  },
};
