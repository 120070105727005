import './polyfills'

import md5 from 'md5'
window.TRANSACTION_ID = md5(new Date().getTime())

import { createApp, h, defineAsyncComponent } from 'vue';

import i18n from './i18n'

import lodash from 'lodash';
import { installLodashPlugin } from './plugins/lodash.js';

import VueEvents from '@/plugins/events/index.js';

import VueEsc from '@/plugins/vue-esc/index.js';

import VueSocketIO from '@/plugins/vue-socket.io'
import SocketIO from 'socket.io-client'

import VueGtag from 'vue-gtag'

import VueContentPlaceholders from '@/plugins/vue-content-placeholders/index.js';

import PortalVue from 'portal-vue'

/** Moment */

import Moment from 'moment'

import 'moment/locale/nl'
import 'moment/locale/fr'
import 'moment-business-days'
import { installMomentPlugin } from '@/plugins/moment.js';

import { extendMoment } from 'moment-range'
const moment = extendMoment(Moment)

import VueColumnsResizable from './plugins/vue-columns-resizable'

/**
 * Mixins
 */

import ModalManagerMixin from '@/mixins/modalManager'

import VueEventsMixin from '@/mixins/events'
import VueDraggMixin from '@/mixins/vueDragg'
import VuexSocketsMixin from '@/mixins/vuexSockets'

import LoadedMixin from '@/mixins/loaded'

import HotJarMixin from '@/mixins/hotjar'
import ToDesktopMixin from '@/mixins/todesktop'

/**
 * Components
 */
import { VueSvgIconPlugin } from '@yzfe/vue-svgicon';
import '@yzfe/svgicon/lib/svgicon.css';

import { installComponents } from '@/ui-components';

import MediaPanel from '@/components/MediaPanel/Index.vue'
import GlobalPlanning from '@/components/GlobalPlanning'
import ChecklistsComponent from '@/components/checklists/Index.vue'
import { Emoji } from 'emoji-mart-vue-fast/src'
import UserTitle from '@/components/UserTitle.vue'
import PageTitle from '@/components/user/PageTitle/Index.vue'
import CalendarPicker from '@/components/CalendarPicker/Index.vue'
import LanguageSelect from '@/components/LanguageSelect.vue'

/**
 * Plugins
 */

import './plugins/performance'

import MiddlewaresMixin from '@/middlewares';

import '@/plugins/app'

import SentryPlugin from '@/plugins/sentry'
import VIntercom from '@/plugins/intercom'
import VOneSignal from '@/plugins/onesignal'
import PlaceAutocomplete from '@/plugins/placeAutocomplete'
import Utils from '@/plugins/utils/index.js'
import VersionUpdater from '@/plugins/versionUpdater'

import ConfettiPlugin from '@/plugins/confetti'

import VDrop from '@/plugins/drop'

import { installScrollSaverPlugin } from '@/plugins/scrollSaver';
import { installBusPlugin } from '@/plugins/bus';
import { installNotifyPlugin } from '@/plugins/notify';
import { installClickOutsidePlugin } from '@/plugins/clickOutside.js';
import { installDirectivesPlugin } from '@/plugins/derictives';
import { installDraggPlugin } from '@/plugins/draggable';
import { installQuillEditorPlugin } from '@/plugins/quill/index.js';
import { installTooltipPlugin } from '@/plugins/tooltip.js';

import modals from '@/modals'
import router from '@/routes'
import store from '@/store'
import * as TYPES from '@/store/types.js';

import App from '@/components/App.vue'
import { GOOGLE_MAPS_URL, SOCKET_URL, isProduction } from './config'

import { installModalComponents } from '@/modals/Index.js';
import CobrowseIO from 'cobrowse-sdk-js'

if (process.env.VUE_APP_COBROWSE_LICENSE) {
  CobrowseIO.license = process.env.VUE_APP_COBROWSE_LICENSE;
  CobrowseIO.start();
}

window.store = store
const app = window.app = createApp({
  render: () => h(App),
  // eslint-disable-next-line vue/order-in-components
  mixins: [VueDraggMixin, HotJarMixin, VuexSocketsMixin, VueEventsMixin, ToDesktopMixin],

  // eslint-disable-next-line vue/order-in-components
  data() {
    return {
      removeStoreSubscription: null,
    };
  },

  // eslint-disable-next-line vue/order-in-components
  mounted() {
    this.$onesignal.addListenerForNotificationOpened(res => {
      const data = res?.data

      if (data?.chatId && data?.projectId) {
        this.$router.push({
          name: 'projectChat',
          params: data,
        })
      } else if (data?.chatId) {
        this.$router.push({
          name: 'teamChat',
          params: data,
        })
      }
    })
  },

  // eslint-disable-next-line vue/order-in-components
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.$events.emit('route:change', to, from)
      next()
    })

    this.$router.afterEach((to, from) => {
      this.$events.emit('route:updated', to, from);
    });

    window.addEventListener('keydown', e => {
      this.$events.emit(`keydown:${e.keyCode}`, e)
    })

    this.removeStoreSubscription = this.$store.subscribe(({ type, payload }) => {
      if (type === TYPES.CHATS.MOD.UPDATE_CHAT_MESSAGE_COUNTER) {
        const { chatId, counter } = payload;

        const chat = this.$store.getters['Chats/getChatById'](chatId);
        const project = chat
          ? this.$store.getters['Projects/getProjectById'](Number(chat.projectId))
          : this.$store.state.Projects.projects.find(({ mentionChats }) =>
              new Set(mentionChats).has(chatId)
            );

        if (project) {
          const mentionChats = new Set(project.mentionChats);
          if (counter === 0) mentionChats.delete(chatId);

          this.$store.commit(TYPES.PROJECTS.MOD.UPDATE_PROJECT_MENTION_CHATS, {
            projectId: project.id,
            mentionChats: [...mentionChats],
          });
        }
      }
    });
  },

  // eslint-disable-next-line vue/order-in-components
  beforeUnmount() {
    this.removeStoreSubscription?.();
  },
});

window.store = store;
window.router = router;
window.i18n = i18n;

const reconnectingOptions = {
  reconnectionDelay: 1000,
  reconnectionDelayMax: 20 * 1000,
  randomizationFactor: 0.5,
};

app.component('ModalFastAddTasks', defineAsyncComponent(() => import('@/layouts/app/TopBar/modals/FastAddTasks/index.vue')));
app.component('MediaPanel', MediaPanel)
app.component('GlobalPlanning', GlobalPlanning)
app.component('checklists-block', ChecklistsComponent)
app.component('options-pop', defineAsyncComponent(() => import('@/components/OptionsPop.vue')));
app.component('emoji', Emoji)
app.component('user-title', UserTitle)
app.component('page-title', PageTitle)
app.component('calendar-picker', CalendarPicker)
app.component('language-select', LanguageSelect)
app.component('app-banner', defineAsyncComponent(() => import('@/components/AppBanner/Index.vue')));

app
  .use(store)
  .use(router)
  .use(i18n)
  .use(installComponents)
  .use(VueColumnsResizable)
  .use(installLodashPlugin, { lodash })
  .use(VueSvgIconPlugin, {
    defaultWidth: '1em',
    defaultHeight: '1em',
  })
  .use(VueEvents)
  .use(VueEsc)
  .use(VueContentPlaceholders)
  .use(installMomentPlugin, { moment })
  .use(PortalVue)
  .use(modals)
  .use(VersionUpdater)
  .use(
    new VueSocketIO({
      connection: isProduction
        ? SocketIO(SOCKET_URL, reconnectingOptions)
        : SocketIO('/', {
            path: SOCKET_URL,
            ...reconnectingOptions,
          })
    })
  )
  .use(SentryPlugin, {
    dsn: process.env.VUE_APP_SENTRY_DSN,
  })
  .use(VIntercom, {
    app_id: process.env.VUE_APP_INTERCOM_APP_ID,
    hide_default_launcher: true,
  })
  .use(VOneSignal, {
    appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
    subdomainName: process.env.VUE_APP_ONESIGNAL_SUBDOMAIN,
  })
  .use(PlaceAutocomplete, {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    url: GOOGLE_MAPS_URL,
  })
  .use(Utils)
  .use(VDrop)
  .use(ConfettiPlugin);

if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID) {
  app.use(
    VueGtag,
    {
      config: {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
      },
    },
    router,
  );
}

app.use(installModalComponents);

app.use(installScrollSaverPlugin);
app.use(installBusPlugin);
app.use(installNotifyPlugin);
app.use(installClickOutsidePlugin);
app.use(installDirectivesPlugin);
app.use(installDraggPlugin);
app.use(installQuillEditorPlugin);
app.use(installTooltipPlugin);

app.mixin(MiddlewaresMixin)
app.mixin(ModalManagerMixin)
app.mixin(LoadedMixin)

app.mount('#app');
