import Store from '@/store'
import * as TYPES from '../store/types'
import busEmitter from '@/plugins/bus'

import {
  TEAM_CHAT_STATUS,
  MessageModel,
  ProjectModel,
  UserModel,
  FolderModel,
  FilterModel,
  ChatModel,
  ChecklistModel,
  ChecklistItemModel,
  ProjectStatusModel,
  LabelModel,
  UserInvitedModel,
  IntegrationDetailModel,
  CommentModel,
  CompanyModel,
  MediaFileModel,
  RegistrationTypeModel,
  ProjectContactModel,
  ProjectCalculation,
  RegistrationModel,
  NotificationModel,
  PublicDescriptionHistoryModel,
  PlanningResourceGroupModel,
  PlanningResourceModel,
  PlanningResourcePlanItemModel,
  ImportFileModel,
  ImportFileResultsModel,
  ProjectImportModel,
  MaterialImportModel,
  FormModel,
  FormLogModel,
  IntegrationMappingModel,
} from '../models'

import { mapGetters } from 'vuex'
import { isUndefined } from 'lodash'
import RegistrationTypesGroupModel from '@/models/RegistrationTypesGroup'
import ReferralModel from '@/models/Referral'
import { INTEGRATION_DETAIL_STATUS, INTEGRATIONS } from '@/constants'

export default {
  sockets: {
    connect() {
      const mustRefresh = !this.network
      Store.commit(`App/${TYPES.APP.CHANGE_NETWORK}`, true)

      if (mustRefresh) {
        this.$events.emit('network:refresh')
        debug('[!] Init socket connect')
      }
    },

    disconnect() {
      busEmitter.emit('network:disconnect');

      this.$events.emit('network:disconnect')
      Store.commit(`App/${TYPES.APP.CHANGE_NETWORK}`, false)
    },

    'presence:joining'(ctx) {
      // Check joining to user company
      if (ctx[0].includes('presence-company')) {
        Store.commit(TYPES.DIRECT_CHATS.MOD.SWITCH_CHAT_STATUS, {
          id: ctx[1].user_id,
          status: TEAM_CHAT_STATUS.ONLINE,
        })
      }
    },

    'presence:leaving'(ctx) {
      // Check leaving from user company
      if (ctx[0].includes('presence-company')) {
        Store.commit(TYPES.DIRECT_CHATS.MOD.SWITCH_CHAT_STATUS, {
          id: ctx[1].user_id,
          status: TEAM_CHAT_STATUS.OFFLINE,
        })
      }
    },

    'presence:subscribed'(ctx) {
      if (ctx[0].includes('presence-company')) {
        Store.commit(
          TYPES.DIRECT_CHATS.MOD.INIT_CHAT_STATUSES,
          ctx[1].map(e => {
            return {
              id: e.user_id,
              status: TEAM_CHAT_STATUS.ONLINE,
            }
          })
        )
      }
    },

    UserChecklistitemCountEvent(ctx) {
      const countTasks = ctx[1]?.counter || 0

      Store.commit(TYPES.USERS.MOD.INIT_USER, { ...this.me, countTasks })
    },

    ChecklistItemEvent(ctx) {
      let item = ctx[1]?.checklistItem
      if (!item) return

      Store.dispatch('Checklists/processChecklistItemFromSocketEvent', item);
    },

    ChecklistItemMembersEvent(ctx) {
      const checklistItemId = ctx[1].checklist_item_id ? Number(ctx[1].checklist_item_id) : null;
      const checklistItemUserIds = Array.isArray(ctx[1].checklist_item_members)
        ? ctx[1].checklist_item_members.map(({ id }) => Number(id))
        : [];
      const checklistId =
        Store.state.Checklists.checklistIdsByChecklistItemIds[checklistItemId] ?? null;

      if (checklistItemId === null || checklistItemUserIds === null || checklistId === null) return;

      Store.dispatch('Checklists/setChecklistItemMembersLocally', {
        checklistId,
        checklistItemId,
        userIds: checklistItemUserIds,
      });
    },

    FilterEvent(ctx) {
      const filter = ctx[1]?.filter
      if (!filter) return

      if (filter.deleted_at)
        Store.commit(TYPES.FILTERS.MOD.DELETE_FILTER, filter.id)
      else Store.commit(TYPES.FILTERS.MOD.INIT_FILTERS, new FilterModel(filter))
    },

    ProjectDescriptionHistoryEvent(ctx) {
      const data = ctx[1]

      Store.commit(
        TYPES.PROJECTS.MOD.INIT_PUBLIC_DESCRIPTION_HISTORY,
        data.publicDescriptionHistory.map(
          e => new PublicDescriptionHistoryModel(e)
        )
      )
    },

    RegistrationEvent(ctx) {
      const data = ctx[1]

      const registration = new RegistrationModel(data.registration);
      const matches = Store.getters['Registrations/matchRegistrationWithFilters'](registration);

      if (matches) {
        Store.commit(TYPES.REGISTRATIONS.MOD.INIT_REGISTRATIONS, registration);
      } else {
        Store.commit(TYPES.REGISTRATIONS.MOD.DELETE_REGISTRATION, registration.id);
      }
    },

    NotificationEvent(ctx) {
      const data = ctx[1]

      if (!data.notification.data?.type) return;

      Store.commit(
        TYPES.NOTIFICATIONS.MOD.INIT_NOTIFICATIONS,
        new NotificationModel(data.notification)
      )
    },

    FolderEvent(ctx) {
      const data = ctx[1]

      if (data.folder.parent_id)
        Store.commit(TYPES.FOLDERS.MOD.CHANGE_COUNT_FOLDERS, {
          id: data.folder.parent_id,
          position: !data.folder.deleted_at,
        })

      if (data.folder.deleted_at) {
        Store.commit(TYPES.FOLDERS.MOD.DELETE_FOLDERS, data.folder.id)
      } else {
        Store.commit(
          TYPES.FOLDERS.MOD.INIT_FOLDERS,
          new FolderModel(data.folder)
        )
      }
    },

    MessageCounterEvent(ctx) {
      const data = ctx[1],
        chats = data.counter.chats,
        projects = data.counter.projects

      for (const chatId in chats) {
        Store.commit(`Chats/${TYPES.CHATS.UPDATE_CHAT_MESSAGE_COUNTER}`, {
          chatId: Number(chatId),
          counter: chats[chatId],
        })
      }

      for (const projectId in projects) {
        Store.commit(
          `Projects/${TYPES.PROJECTS.UPDATE_PROJECT_MESSAGE_COUNTER}`,
          {
            projectId: Number(projectId),
            counter: projects[projectId],
          }
        )
      }
    },

    MessageReactionEvent(ctx) {
      const data = ctx[1]

      Store.commit(
        `Messages/${TYPES.MESSAGES.UPDATE_MESSAGE_REACTIONS}`,
        new MessageModel(data.message)
      )
    },

    NewMessage(ctx) {
      const data = ctx[1]

      const message = new MessageModel({
        ...data.message,
        user: data.message_user,
        join_leave_user: data.join_leave_user,
        media: data.message_attachment,
        project_id: data.chat_project_id,
      })

      const projectId = data.chat_project_id ? Number(data.chat_project_id) : null;
      const { chatId } = message;

      Store.commit(TYPES.MESSAGES.MOD.INIT_MESSAGES, message)
      Store.commit(TYPES.PROJECTS.MOD.UPDATE_LAST_MESSAGE_AT, {
        projectId: data.chat_project_id,
        lastMessageAt: message.createdAt,
      })
      Store.commit(TYPES.CHATS.MOD.UPDATE_LAST_MESSAGE, message)

      if (chatId) {
        const userId = Store.getters['User/me']?.id;
        if (!userId) return;

        const displayMention = new RegExp(`@userid:${userId}(\\D|$)`, 'gi').test(message.text);

        if (displayMention) {
          Store.commit(TYPES.CHATS.MOD.UPDATE_CHAT_DISPLAY_MENTION, {
            chatId: message.chatId,
            displayMention,
          });

          if (projectId) {
            const project = Store.getters['Projects/getProjectById'](projectId);
            const mentionChats = new Set(project?.mentionChats ?? []);
            mentionChats.add(chatId);

            Store.commit(TYPES.PROJECTS.MOD.UPDATE_PROJECT_MENTION_CHATS, {
              projectId,
              mentionChats: [...mentionChats],
            });
          }
        }
      }

      if (message.isMedia) {
        Store.commit(`Files/${TYPES.FILES.INIT_FILES}`, message.media)
      }
    },

    MessageDeleted(ctx) {
      const data = ctx[1]
      Store.commit(`Messages/${TYPES.MESSAGES.DELETE_MESSAGE}`, data.message_id)

      if (data.last_message) {
        Store.commit(
          `Chats/${TYPES.CHATS.UPDATE_LAST_MESSAGE}`,
          new MessageModel(data.last_message)
        )
      }
    },

    ProjectEvent(ctx) {
      const data = ctx[1]
      let project = new ProjectModel(data.project)
      Store.commit(
        `Projects/${TYPES.PROJECTS.INIT_PROJECTS}`,
        project
      )
    },

    ProjectContactEvent(ctx) {
      const data = ctx[1]?.contact ?? {};
      const projectId = data.project_id;
      if (!projectId) return;

      const contact = new ProjectContactModel(data);
      const isSecure = this.me.isMember && contact.access;

      if (contact.isDeleted || isSecure) {
        Store.commit(TYPES.PROJECTS.MOD.REMOVE_PROJECT_CONTACT, { projectId, contactId: contact.id });
      } else {
        Store.dispatch('Projects/processProjectContactSocketEvent', { projectId, contact })
      }
    },

    FileEvent(ctx) {
      const data = ctx[1],
        file = new MediaFileModel(data.file)

      Store.commit(`Files/${TYPES.FILES.INIT_FILES}`, file)

      if (file.projectId) {
        Store.commit(`Projects/${TYPES.PROJECTS.UPDATE_PROJECT_FILE_COUNTER}`, {
          access: file.isLocked,
          projectId: file.projectId,
          inc: file.isDeleted ? -1 : 1,
        })
      }

      if (file.folderId)
        Store.commit(TYPES.FOLDERS.MOD.CHANGE_COUNT_FILES, {
          id: file.folderId,
          position: !file.isDeleted,
        })
    },

    /**
     * Update user account info
     */
    UserEvent(ctx) {
      const data = ctx[1]

      if (data.user.id === this.me.id) {
        Store.commit(`User/${TYPES.USERS.INIT_USER}`, new UserModel(data.user))
      }
    },

    ChatEvent(ctx) {
      const data = ctx[1]

      if (data.chat.deleted_at) {
        Store.commit(`Chats/${TYPES.CHATS.DELETE_CHAT}`, data.chat.id)
      } else {
        Store.commit(
          `Chats/${TYPES.CHATS.INIT_CHATS}`,
          new ChatModel(data.chat)
        )
      }
    },

    ChecklistEvent(ctx) {
      const data = ctx[1]

      if (data.checklist.deleted_at) {
        Store.commit(
          TYPES.CHECKLISTS.MOD.DELETE_CHECKLIST,
          data.checklist.id
        )
      } else {
        const modelChecklist = new ChecklistModel(data.checklist)
        const { checklistsByIds } = Store.state.Checklists;

        if (checklistsByIds[modelChecklist.id]) {
          Store.commit(TYPES.CHECKLISTS.MOD.UPDATE_CHECKLIST, modelChecklist)
        } else {
          Store.commit(TYPES.CHECKLISTS.MOD.INIT_CHECKLIST, modelChecklist)
        }
      }
    },

    CompanyProjectStatusEvent(ctx) {
      const data = ctx[1]

      Store.commit(
        TYPES.COMPANYPROJECTSTATUS.MOD.INIT_STATUSES,
        new ProjectStatusModel(data.company_project_status)
      )
    },

    LabelEvent(ctx) {
      const data = ctx[1]

      if (data.label.deleted_at) {
        Store.dispatch('Labels/deleteLabelLocally', data.label.id)
      } else {
        Store.commit(
          `Labels/${TYPES.LABELS.INIT_LABEL}`,
          new LabelModel(data.label)
        )
      }
    },

    ChatMembersEvent(ctx) {
      const data = ctx[1]

      Store.commit(TYPES.CHATS.MOD.SET_CHAT_MEMBERS, {
        members: data.chat_members.map(e => new UserModel(e)),
        chatId: Number(data.chat_id),
      })
    },

    UserInviteEvent(ctx) {
      const data = ctx[1]

      if (data.userInvite) {
        if (data.userInvite.deleted || data.userInvite.used_at) {
          Store.commit(
            `InviteMembers/${TYPES.INVITEMEMBERS.DELETE_INVITE}`,
            data.userInvite.token
          )
        } else {
          Store.commit(
            `InviteMembers/${TYPES.INVITEMEMBERS.INIT_INVITES}`,
            new UserInvitedModel(data.userInvite)
          )
        }
      }
    },

    /**
     * Handle delete and add documents
     *
     * @param {Socket} ctx
     */
    CommentEvent(ctx) {
      const data = ctx[1]

      if (data.comment.deleted_at) {
        Store.commit(
          `Comments/${TYPES.COMMENTS.DELETE_COMMENTS}`,
          data.comment.id
        )
      } else {
        Store.commit(
          `Comments/${TYPES.COMMENTS.INIT_COMMENTS}`,
          new CommentModel(data.comment)
        )
      }
    },

    CompanyMembersEvent(ctx) {
      const data = ctx[1]

      Store.commit(
        `Companies/${TYPES.COMPANIES.INIT_COMPANY_MEMBERS}`,
        data.company_members.map(e => new UserModel(e))
      )
    },

    CompanyEvent(ctx) {
      const data = ctx[1]

      Store.dispatch('Companies/setCompany', new CompanyModel(data.company))
    },

    /**
     * Registrations Events
     *
     * @param {Socket} ctx
     */
    RegistrationTypeEvent(ctx) {
      const data = ctx[1]

      if (data.registrationType.id) {
        Store.commit(
          `RegistrationTypes/${TYPES.REGISTRATION_TYPES.INIT_REGISTRATION_TYPES}`,
          new RegistrationTypeModel(data.registrationType)
        )
      }
    },

    RegistrationTypeGroupEvent(ctx) {
      const data = ctx[1]
      if (data.registrationTypeGroup.id) {
        Store.commit(
          `RegistrationTypeGroups/${TYPES.REGISTRATION_TYPES_GROUPS.INIT_REGISTRATION_TYPES_GROUPS}`,
          new RegistrationTypesGroupModel(data.registrationTypeGroup)
        )
      }
    },


    ProjectCalculationEvent(ctx) {
      const data = ctx[1]

      if (data.projectCalculation.deleted_at === null) {
        Store.commit(
          `ProjectCalculations/${TYPES.PROJECT_CALCULATIONS.INIT_PROJECT_CALCULATIONS}`,
          new ProjectCalculation(data.projectCalculation)
        )
      } else {
        Store.commit(
          `ProjectCalculations/${TYPES.PROJECT_CALCULATIONS.DELETE_PROJECT_CALCULATION}`,
          new ProjectCalculation(data.projectCalculation).id
        )
      }
    },

    PlanningPlanitemEvent(ctx) {
      const data = ctx[1]
      if (data.planningPlanitem.deleted_at === null) {
        Store.commit(
          `PlanningResourcePlanItems/${TYPES.PLANNING_RESOURCE_PLAN_ITEMS.SET_RESOURCE_PLAN_ITEM}`,
          new PlanningResourcePlanItemModel(data.planningPlanitem)
        )
      } else {
        Store.commit(
          `PlanningResourcePlanItems/${TYPES.PLANNING_RESOURCE_PLAN_ITEMS.DELETE_RESOURCE_PLAN_ITEMS}`,
          new PlanningResourcePlanItemModel(data.planningPlanitem).id
        )
      }
    },

    PlanningResourceEvent(ctx) {
      const data = ctx[1]
      if (data.planningResource.deleted_at === null) {
        Store.commit(
          `PlanningResources/${TYPES.PLANNING_RESOURCES.INIT_RESOURCES}`,
          new PlanningResourceModel(data.planningResource)
        )
      } else {
        Store.commit(
          `PlanningResources/${TYPES.PLANNING_RESOURCES.DELETE_RESOURCES}`,
          new PlanningResourceModel(data.planningResource).id
        )
      }
    },

    PlanningResourceGroupEvent(ctx) {
      const data = ctx[1]
      if (data.planningResourceGroup.deleted_at === null) {
        Store.commit(
          `PlanningResourceGroups/${TYPES.PLANNING_RESOURCE_GROUPS.INIT_GROUPS}`,
          new PlanningResourceGroupModel(data.planningResourceGroup)
        )
      } else {
        Store.commit(
          `PlanningResourceGroups/${TYPES.PLANNING_RESOURCE_GROUPS.DELETE_GROUPS}`,
          new PlanningResourceGroupModel(data.planningResourceGroup).id
        )
      }
    },

    CompanyIntegrationEvent(ctx) {
      const data = ctx[1];

      if (data?.detail) {
        const detailModel = new IntegrationDetailModel(data.detail);
        Store.commit(
          TYPES.INTEGRATIONS.MOD.INIT_INTEGRATION_DETAILS,
          detailModel,
        );

        const status = data.detail.status;
        const integrationId = Number(data.detail.integration_id);

        if (!isUndefined(status) && integrationId) {
          Store.commit(
            TYPES.INTEGRATIONS.MOD.UPDATE_INTEGRATION_STATUS,
            {
              integrationId,
              status,
            }
          );

          const getIntegrationDetailByIntegrationSlug =
            Store.getters['Integrations/getIntegrationDetailByIntegrationSlug'];
          const sharepointDetail = getIntegrationDetailByIntegrationSlug?.(INTEGRATIONS.SHAREPOINT);

          if (
            status === INTEGRATION_DETAIL_STATUS.STOPPED &&
            sharepointDetail.id === detailModel.id
          ) {
            Store.commit(TYPES.PROJECTS.MOD.DELETE_PROJECT_INTEGRATION_FOR_ALL, {
              integrationId,
            });
          }
        }
      }
    },

    ImportCsvFileEvent(ctx) {
      const data = ctx[1]

      if (data?.importCsvFile) {
        Store.dispatch(
          'ImportFiles/processImportCsvFileSocketEvent',
          new ImportFileModel(data.importCsvFile)
        )
      }
    },

    ImportCsvResultsEvent(ctx) {
      const data = ctx[1]

      if (data?.importCsvResult) {
        Store.dispatch(
          'ImportFiles/processImportCsvSocketEvent',
          new ImportFileResultsModel(data.importCsvResult)
        )
      }
    },

    ImportRegistrationTypeCsvEvent(ctx) {
      const data = ctx[1]

      if (data?.importCsvRegistrationTypeResult) {
        Store.dispatch(
          'MaterialsImports/processImportCsvSocketEvent',
          new MaterialImportModel(data.importCsvRegistrationTypeResult)
        )
      }
    },

    FormEvent(ctx) {
      const data = ctx[1];

      if (data.form.deleted_at) {
        Store.dispatch('Forms/removeFormLocally', data.form.id);
      } else if (data.form) {
        Store.dispatch('Forms/initForms', new FormModel(data.form));
      }
    },

    FormAttachedToChecklistitemEvent(ctx) {
      const item = ctx[1]?.checklistItem;
      if (!item) return;

      Store.dispatch('Checklists/processChecklistItemFromSocketEvent', item);
    },

    FormLogEvent(ctx) {
      const { checklistItem, log } = ctx[1] ?? {};

      if (checklistItem) {
        Store.dispatch('Checklists/processChecklistItemFromSocketEvent', checklistItem);
      }

      if (log) {
        Store.dispatch('Forms/addFormLogsLocally', new FormLogModel(log));
      }
    },

    ProjectIntegrationMappingCreatedEvent(ctx) {
      const data = ctx[1];

      const integrationMappings =
        data?.project?.integration_mappings?.map?.(
          mapping => new IntegrationMappingModel(mapping)
        ) ?? null;

      if (!integrationMappings) return;

      integrationMappings.forEach(integrationMapping => {
        Store.commit(
          TYPES.INTEGRATIONS.MOD.INIT_INTEGRATION_DETAILS,
          integrationMapping.integration,
        );

        Store.commit(
          TYPES.PROJECTS.MOD.INIT_PROJECT_INTEGRATION,
          {
            projectId: data.project.id,
            integrationMapping,
          }
        );
      });
    },

    ReferralInviteUpdateEvent(ctx) {
      const referral = ctx[1]?.referral;
      if (referral) {
        Store.commit(TYPES.REFERRALS.MOD.SET_REFERRAL, new ReferralModel(referral));
      }
    },

    ReferralInviteCreateEvent(ctx) {
      const referral = ctx[1]?.referral;
      if (referral) {
        Store.commit(TYPES.REFERRALS.MOD.SET_REFERRAL, new ReferralModel(referral));
      }
    },
  },

  computed: {
    ...mapGetters('User', ['me']),
    ...mapGetters('App', ['network']),
  },
}
