import Provider from '@provider'
import { FolderModel } from '@/models'

export default {
  downloadZipFolder: ({ folder, progress = () => {} }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(
          `/folders/${folder.remoteId ? 8 : folder.id}/download_zip`,
          folder.remoteId ? { remote_folder_id: folder.remoteId } : null,
          {
            cache: 'no-cache',

            responseType: 'arraybuffer',
            params: {
              d: Date.now(),
            },

            onDownloadProgress: progressEvent => {
              let progressPercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              progress(
                progressPercent < 100 ? progressPercent : 0,
                progressEvent
              )
            },
          }
        )
        .then(response => {
          let blob = new Blob([response.data], {
            type: 'application/zip',
          })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${folder.name}.zip`
          link.click()

          resolve()
        })
        .catch(reject)
    })
  },

  addFolder: ({ name, parentId, projectId, chatId, access, remoteFolderId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/folders`, {
          name,
          access: access ? 1 : 0,
          parent_id: parentId,
          remote_folder_id: remoteFolderId,
          chat_id: chatId,
          project_id: projectId,
        })
        .then(response => resolve(new FolderModel(response.data)))
        .catch(reject)
    })
  },

  getFolder: id => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/folders/${id}`)
        .then(response => resolve(new FolderModel(response.data)))
        .catch(reject)
    })
  },

  updateFolder: ({ folderId, name, parentId, access }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/folders/${folderId}`, {
          name,
          parent_id: parentId,
          access,
        })
        .then(response => resolve(new FolderModel(response.data)))
        .catch(reject)
    })
  },

  updateRemoteFolder: ({ remoteId, remoteFolderId, projectId, name, access }) => {
    return Provider.api
      .put(`/folders/`, {
        remote_item_id: remoteId,
        remote_folder_id: remoteFolderId,
        project_id: projectId,
        name,
        access,
      })
      .then(response => response.data);
  },

  deleteFolder: id => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/folders/${id}`)
        .then(response => resolve(response.data))
        .catch(reject)
    })
  },

  deleteRemoteFolder: ({ remoteItemId, projectId }) => {
    return Provider.api
      .delete(`/folders/`, { params: { remote_item_id: remoteItemId, project_id: projectId } })
      .then(response => response.data);
  },
}
