import Store from '@/store'
import ProjectsProvider from '@provider/projects'
import * as TYPES from '@/store/types'

// initial state
const state = {
  projects: [],
  projectsMap: {},
  projectContacts: {},
  chats: [],
  loaded: true,
  publicDescriptionHistory: [],
  isHoursBudgetUpdating: false,
  addingContact: {},
  updatingContacts: {},
  integrationsByProjectIds: {},
  remoteFolders: {
    loaded: {},
    loading: {},
  },
}

// getters
const getters = {
  getProjectById: state => id => {
    return state.projectsMap[id] ?? null
  },

  findProjectById: state =>
    state.projects.reduce((out, item) => {
      out[item.id] = item
      return out
    }, {}),

  projectPublicDescriptionHistory: state => id =>
    state.publicDescriptionHistory.filter(e => e.projectId == id),

  hasUnreadMessage: state => {
    return !!state.projects.find(e => e.countNewMessages > 0)
  },

  projects: state => {
    return state.projects.filter(e => !e.isArchived)
  },

  getterProjectsByFilters: state => ({ requireIds = [] }) => {
    let l = state.projects.filter(e => !e.isArchived)

    requireIds = Array.isArray(requireIds) ? requireIds : [requireIds]
    requireIds.forEach(id => {
      if (!l.find(e => e.id == id)) {
        let fP = state.projects.find(e => e.id == id)
        if (fP) l.push(fP)
      }
    })

    return l
  },

  /**
   * Get an integration info for a project
   * @returns {{integration: any, integrationDetail: any}|null}
   */
  getIntegrationInfoByProjectId: (state, _getters, _rootState, rootGetters) => projectId => {
    const integrationMappings = state.integrationsByProjectIds[projectId];
    if (!integrationMappings || !integrationMappings?.length) return null;

    const [integrationMapping] = integrationMappings;
    const { integration: integrationDetail } = integrationMapping;

    const { integrationId } = integrationDetail;
    const getIntegrationsByIds = rootGetters['Integrations/getIntegrationsByIds'];

    const integration = getIntegrationsByIds([integrationId])[0];

    return {
      integration,
      integrationMapping,
      integrationDetail,
    };
  },

  getAllIntegrationsInfoByProjectId: (state, _getters, _rootState, rootGetters) => projectId => {
    const integrationMappings = state.integrationsByProjectIds[projectId];
    if (!integrationMappings) return null;

    const getIntegrationsByIds = rootGetters['Integrations/getIntegrationsByIds'];

    return integrationMappings.map(integrationMapping => {
      const { integration: integrationDetail } = integrationMapping;
      const { integrationId } = integrationDetail;
      const integration = getIntegrationsByIds([integrationId])[0];

      return {
        integration,
        integrationMapping,
        integrationDetail,
      };
    });
  },

  allProjects: state => state.projects,
  loadedProjects: state => state.loaded,

  getContactsByProjectId: state => id => {
    return state.projectContacts[id];
  },

  getUpdatingContactsByProjectId: state => id => {
    return state.updatingContacts[id];
  },

  getAddingContactByProjectId: state => id => {
    return state.addingContact[id];
  },

  isLoadingRemoteFolder: state => remoteFolderId => !!state.remoteFolders.loading[remoteFolderId],
}

// actions
const actions = {
  getProjectPublicDescriptionHistory({ commit }, { projectId }) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.getProjectPublicDescriptionHistory({ projectId })
        .then(list => {
          commit(TYPES.PROJECTS.INIT_PUBLIC_DESCRIPTION_HISTORY, list)
          resolve(list)
        })
        .catch(reject)
    })
  },

  getProjectUsedContacts(_, { search, cancelToken }) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.getProjectUsedContacts({ search, cancelToken })
        .then(resolve)
        .catch(reject)
    })
  },

  exportProjectHours(
    _,
    { fileName, projectId, users = false, hours = false, dateFrom, dateTill }
  ) {
    return ProjectsProvider.exportProjectHours({
      fileName,
      projectId,
      users,
      hours,
      dateFrom,
      dateTill,
    })
  },

  markMessagesAsRead({ commit }, projectId) {
    return ProjectsProvider.markMessagesAsRead(projectId)
      .then(() => {
        commit(TYPES.PROJECTS.UPDATE_PROJECT_MESSAGE_COUNTER, { projectId, counter: 0 });
      });
  },

  markProject({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.markProject(projectId)
        .then(project => {
          commit(TYPES.PROJECTS.INIT_PROJECTS, project)
          resolve(project)
        })
        .catch(reject)
    })
  },

  unmarkProject({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.unmarkProject(projectId)
        .then(project => {
          commit(TYPES.PROJECTS.INIT_PROJECTS, project)
          resolve(project)
        })
        .catch(reject)
    })
  },

  getProjects({ commit }, form) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.getProjects()
        .then(projects => {
          commit('INIT_PROJECTS', projects)
          resolve(projects)
          commit('SWITCH_LOADED', false)
        })
        .catch(reject)
    })
  },

  getArchivedProjects({ commit }) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.getArchivedProjects()
        .then(projects => {
          commit('INIT_PROJECTS', projects)
          resolve(projects)
        })
        .catch(reject)
    })
  },

  getProject({ commit }, { projectId, cancelToken }) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.getProject({ projectId, cancelToken })
        .then(project => {
          commit(TYPES.PROJECTS.INIT_PROJECTS, project)
          resolve(project)
        })
        .catch(reject)
    })
  },

  deleteProject({ commit }, id) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.deleteProject(id)
        .then(() => {
          commit(TYPES.PROJECTS.DELETE_PROJECT, id)
          resolve()
        })
        .catch(reject)
    })
  },

  duplicateProject({ commit }, { id, options }) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.duplicateProject(id, { options })
        .then(project => {
          commit(TYPES.PROJECTS.INIT_PROJECTS, project)
          resolve(project)
        })
        .catch(reject)
    })
  },

  deleteProjectMember({ commit }, { projectId, userId }) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.deleteProjectMember({ projectId, userId })
        .then(() => {
          commit(TYPES.PROJECTS.DELETE_PROJECT_MEMBER, { projectId, memberId: userId });
          resolve()
        })
        .catch(reject)
    })
  },

  archiveProject: ({ commit }, projectId) => {
    return new Promise((resolve, reject) => {
      ProjectsProvider.archiveProject(projectId)
        .then(project => {
          resolve(project)
          commit(TYPES.PROJECTS.INIT_PROJECTS, project)
        })
        .catch(reject)
    })
  },

  dearchiveProject: ({ commit }, projectId) => {
    return new Promise((resolve, reject) => {
      ProjectsProvider.dearchiveProject(projectId)
        .then(project => {
          commit(TYPES.PROJECTS.INIT_PROJECTS, project)
          resolve(project)
        })
        .catch(reject)
    })
  },

  addProject({ commit }, form) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.addProject(form)
        .then(project => {
          commit(TYPES.PROJECTS.INIT_PROJECTS, project)
          resolve(project)
        })
        .catch(reject)
    })
  },

  addProjectMember({ commit }, { projectId, user }) {
    return ProjectsProvider.addProjectMember({ projectId, userId: user.id })
      .then(() => {
        commit(TYPES.PROJECTS.INIT_PROJECT_MEMBER, {
          projectId: Number(projectId),
          memberId: user.id,
        });
      });
  },

  inviteExternalUser({ state, commit }, { projectId, user }) {
    return ProjectsProvider.inviteExternalUser({ projectId, user })
  },

  getProjectMembers({ }, projectId) {
    return ProjectsProvider.getProjectMembers(projectId)
  },

  getProjectChats({ }, { projectId, countShowUsers }) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.getProjectChats({ projectId, countShowUsers })
        .then(list => {
          store.commit('Chats/INIT_CHATS', list)
          resolve(list)
        })
        .catch(reject)
    })
  },

  getProjectComments({ }, { projectId, offset, limit = 5 }) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.getProjectComments({ projectId, offset, limit })
        .then(list => {
          store.commit('Comments/INIT_COMMENTS', list)
          resolve(list)
        })
        .catch(reject)
    })
  },

  getProjectFiles(
    { state, commit },
    { projectId, access, limit, withFolders }
  ) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.getProjectFiles({
        projectId,
        access,
        limit,
        withFolders,
      })
        .then(({ files, folders }) => {
          commit(TYPES.FOLDERS.MODULE + TYPES.FOLDERS.INIT_FOLDERS, folders, {
            root: true,
          })
          commit(TYPES.FILES.MODULE + TYPES.FILES.INIT_FILES, files, {
            root: true,
          })

          resolve(files)
        })
        .catch(reject)
    })
  },

  getSharepointProjectContents({ state, commit }, { projectId, remoteFolderId }) {
    // if (state.remoteFolders.loaded[remoteFolderId]) return;

    commit(TYPES.PROJECTS.SET_REMOTE_FOLDER_LOADING, {
      remoteFolderId,
      loading: true,
    });

    commit(TYPES.FILES.MOD.DELETE_FOLDER_FILES, remoteFolderId, { root: true });
    commit(TYPES.FOLDERS.MOD.DELETE_CHILD_FOLDERS, remoteFolderId, { root: true });

    return ProjectsProvider.getSharepointProjectContents({
      projectId,
      remoteFolderId,
    })
      .then(({ files, folders }) => {
        commit(TYPES.FOLDERS.MOD.INIT_FOLDERS, folders, {
          root: true,
        });

        commit(TYPES.FILES.MOD.INIT_FILES, files, {
          root: true,
        });

        commit(TYPES.PROJECTS.SET_REMOTE_FOLDER_LOADED, { remoteFolderId, loaded: true });

        return { files, folders };
      })
      .finally(() => {
        commit(TYPES.PROJECTS.SET_REMOTE_FOLDER_LOADING, {
          remoteFolderId,
          loading: false,
        });
      });
  },

  updateProject({ commit }, { ...fields }) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.updateProject({ ...fields })
        .then(project => {
          commit(TYPES.PROJECTS.INIT_PROJECTS, project)
          resolve(project)
        })
        .catch(reject)
    })
  },

  getAllProjectChecklists({ dispatch }, { projectId }) {
    return Promise.allSettled(
      [
        dispatch('getProjectChecklists', {
          projectId,
          withChatChecklist: true,
        }),
        dispatch('getProjectChats', {
          projectId,
          countShowUsers: 0,
        }),
      ],
    );
  },

  getProjectChecklists({ }, { projectId, withChatChecklist }) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.getProjectChecklists({ projectId, withChatChecklist })
        .then(checklists => {
          checklists.forEach((checklist) => {
            Store.commit('Checklists/INIT_CHECKLIST', checklist);
          });
          resolve(checklists)
        })
        .catch(reject)
    })
  },

  addProjectLabel({ commit }, { projectId, labelId }) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.addProjectLabel({ projectId, labelId })
        .then(labels => {
          commit('INIT_PROJECT_LABELS', { projectId, labels })
          resolve(project)
        })
        .catch(reject)
    })
  },

  deleteProjectLabel({ commit }, { projectId, labelId }) {
    return new Promise((resolve, reject) => {
      ProjectsProvider.deleteProjectLabel({ projectId, labelId })
        .then(labels => {
          commit('INIT_PROJECT_LABELS', { projectId, labels })
          resolve(project)
        })
        .catch(reject)
    })
  },

  updatePosition({ state, commit }, { projectId, position, statusId }) {
    return new Promise((resolve, reject) => {
      let project = state.projects.find(e => e.id == projectId),
        save = { ...project }

      commit(TYPES.PROJECTS.UPDATE_POSITION, { projectId, position, statusId })

      ProjectsProvider.updateProject({
        projectId,
        statusId,
        position,
      })
        .then(project => {
          commit(TYPES.PROJECTS.INIT_PROJECTS, project)
          resolve(project)
        })
        .catch(error => {
          commit(TYPES.PROJECTS.INIT_PROJECTS, save)
          reject(error)
        })
    })
  },

  async updateProjectHoursBudget({ state, commit }, { projectId, hoursBudget }) {
    try {
      commit(TYPES.PROJECTS.SET_IS_HOURS_UPDATING, true);

      const project = await ProjectsProvider.updateProject({ projectId, hoursBudget })

      commit(TYPES.PROJECTS.INIT_PROJECTS, project)
    } finally {
      commit(TYPES.PROJECTS.SET_IS_HOURS_UPDATING, false);
    }
  },

  getProjectContacts: ({ commit }, { projectId }) => {
    return ProjectsProvider.getProjectContacts({ projectId }).then(contacts => {
      commit(TYPES.PROJECTS.SET_PROJECT_CONTACTS, {
        projectId,
        contacts
      });
    });
  },

  addProjectContact: ({ commit }, { projectId, contact }) => {
    const { name, email, phone, address } = contact;

    commit(TYPES.PROJECTS.SET_ADDING_CONTACT, { projectId, isAdding: true });
    return ProjectsProvider.addProjectContact({
      projectId,
      name,
      email,
      phone,
      address,
    })
      .then(addedContact => {
        commit(TYPES.PROJECTS.INIT_PROJECT_CONTACT, { projectId, contact: addedContact });
      })
      .finally(() => {
        commit(TYPES.PROJECTS.SET_ADDING_CONTACT, { projectId, isAdding: false });
      });
  },

  updateProjectContact: ({ commit }, { projectId, contact }) => {
    const { id, name, email, phone, address, access } = contact;

    commit(TYPES.PROJECTS.ADD_UPDATING_CONTACT, { projectId, contactId: id });
    return ProjectsProvider.updateProjectContact({
      contactId: id,
      projectId,
      name,
      email,
      phone,
      address,
      access,
    })
      .then(updatedContact => {
        commit(TYPES.PROJECTS.UPDATE_PROJECT_CONTACT, { projectId, contact: updatedContact });
        commit(TYPES.PROJECTS.SET_PROJECT_DEFAULT_CONTACT, { projectId, contact: updatedContact });
      })
      .finally(() => {
        commit(TYPES.PROJECTS.REMOVE_UPDATING_CONTACT, { projectId, contactId: id });
      });
  },

  deleteProjectContact: ({ commit }, { projectId, contactId }) => {
    commit(TYPES.PROJECTS.ADD_UPDATING_CONTACT, { projectId, contactId });
    return ProjectsProvider.deleteProjectContact({
      contactId,
      projectId,
    })
      .then(() => {
        commit(TYPES.PROJECTS.REMOVE_PROJECT_CONTACT, { projectId, contactId });
      })
      .finally(() => {
        commit(TYPES.PROJECTS.REMOVE_UPDATING_CONTACT, { projectId, contactId });
      });
  },

  processProjectContactSocketEvent: ({ state, commit }, { projectId, contact }) => {

    if (state.projectContacts[projectId]?.some(c => c.id === contact.id)) {
      commit(TYPES.PROJECTS.UPDATE_PROJECT_CONTACT, { projectId, contact });
    } else {
      commit(TYPES.PROJECTS.INIT_PROJECT_CONTACT, { projectId, contact });
    }

    commit(TYPES.PROJECTS.SET_PROJECT_DEFAULT_CONTACT, { projectId, contact });

  },

  getProjectIntegrationDetails: ({ commit }, { projectId, cancelToken }) => {
    return ProjectsProvider.getProjectIntegrationDetails({
      projectId,
      cancelToken,
    }).then(integrationMappings => {
      if (!integrationMappings) return;

      integrationMappings.forEach(integrationMapping => {
        commit(TYPES.INTEGRATIONS.MOD.INIT_INTEGRATION_DETAILS, integrationMapping.integration, { root: true });
        commit(TYPES.PROJECTS.INIT_PROJECT_INTEGRATION, { projectId, integrationMapping });
      });
    });
  },
};

// mutations
const mutations = {
  [TYPES.PROJECTS.UPDATE_PROJECT_FILE_COUNTER](
    state,
    { access, projectId, inc = 1 }
  ) {
    let project = state.projects.find(e => e.id == projectId)
    if (!project) return null

    if (access) {
      project.protectedFilesCount += inc
    } else {
      project.filesCount += inc
    }
  },

  [TYPES.PROJECTS.UPDATE_LAST_MESSAGE_AT](state, { projectId, lastMessageAt }) {
    let project = state.projects.find(e => e.id == projectId)
    if (!project) return null

    project.lastMessageAt = lastMessageAt
  },

  [TYPES.PROJECTS.UPDATE_PROJECT_MESSAGE_COUNTER](
    state,
    { projectId, counter }
  ) {
    let project = state.projects.find(e => e.id == projectId)
    if (!project) return null

    project.countNewMessages = counter

    if (counter === 0) {
      // all mentions have been read in all chats
      project.mentionChats = [];
    }
  },

  [TYPES.PROJECTS.UPDATE_PROJECT_MENTION_CHATS](state, { projectId, mentionChats }) {
    let project = state.projects.find(e => e.id == projectId);
    if (!project) return null;

    project.mentionChats = mentionChats;
  },

  [TYPES.PROJECTS.INIT_PROJECTS](state, data) {
    let projects = Array.isArray(data) ? data : [data],
      ids = projects.map(e => e.id)

    state.projects = [
      ...state.projects.filter(e => !ids.includes(e.id)),
      ...projects.filter(e => e.canView && !e.deletedAt),
    ]

    state.projectsMap = state.projects.reduce((result, project) => {
      result[project.id] = project
      return result
    }, {})
  },

  [TYPES.PROJECTS.INIT_PUBLIC_DESCRIPTION_HISTORY](state, data) {
    let list = Array.isArray(data) ? data : [data],
      ids = list.map(e => e.id)

    state.publicDescriptionHistory = [
      ...state.publicDescriptionHistory.filter(e => !ids.includes(e.id)),
      ...list,
    ]
  },

  [TYPES.PROJECTS.UPDATE_POSITION](state, { projectId, position, statusId }) {
    let project = state.projects.find(e => e.id == projectId)

    if (project < 0) return

    let cards = state.projects
      .filter(e => e.companyProjectStatusId == statusId && e.id != projectId)
      .sort((a, b) => a.position - b.position),
      nextCard = cards[position - 1],
      prevCard = cards[position - 2],
      newPos = 1

    if (!prevCard && nextCard) {
      newPos = nextCard.position - 0.0000005
    } else if (!nextCard && prevCard) {
      newPos = prevCard.position + 1
    } else if (prevCard && nextCard) {
      newPos = (prevCard.position + nextCard.position) / 2
    }

    project.position = newPos
    project.companyProjectStatusId = statusId
  },

  [TYPES.PROJECTS.INIT_PROJECT_LABELS](state, { projectId, labels }) {
    let project = state.projects.find(e => e.id === projectId)

    if (project) {
      project.labels = labels
    }
  },

  [TYPES.PROJECTS.REMOVE_PROJECT_LABELS](state,  labelId ) {
    state.projects.forEach(project => {
      const index = project.labels.findIndex(e => e.id === labelId)
      if (index !== -1) {
        project.labels.splice(index, 1)
      }
    })
  },

  [TYPES.PROJECTS.SET_PROJECT_MEMBERS](state, { projectId, members }) {
    const project = state.projects.find(project => project.id === projectId);
    if (project) project.users = members;
  },

  [TYPES.PROJECTS.INIT_PROJECT_MEMBER](state, { projectId, memberId }) {
    const project = state.projects.find(project => project.id === projectId);

    if (project) {
      const index = project.users.indexOf(memberId);

      if (index === -1) {
        project.users.push(memberId);
      }
    }
  },

  [TYPES.PROJECTS.DELETE_PROJECT_MEMBER](state, { projectId, memberId }) {
    const project = state.projects.find(project => project.id === projectId);

    if (project) {
      project.users = project.users.filter((userId) => userId !== memberId);
    }
  },

  [TYPES.PROJECTS.DELETE_PROJECT](state, id) {
    state.projects = state.projects.filter(p => p.id !== id)
  },

  [TYPES.PROJECTS.SWITCH_LOADED](state, status = false) {
    state.loaded = status
  },

  [TYPES.PROJECTS.SET_IS_HOURS_UPDATING](state, isHoursBudgetUpdating) {
    state.isHoursBudgetUpdating = isHoursBudgetUpdating;
  },

  [TYPES.PROJECTS.SET_PROJECT_CONTACTS](state, { projectId, contacts }) {
    state.projectContacts[projectId] = contacts;
  },

  [TYPES.PROJECTS.INIT_PROJECT_CONTACT](state, { projectId, contact }) {
    const projectContacts = state.projectContacts[projectId] ?? [];

    const newProjectContacts = [
      ...projectContacts.filter(c => c.id !== contact.id),
      contact
    ];

    state.projectContacts[projectId] = newProjectContacts;
  },

  [TYPES.PROJECTS.SET_PROJECT_DEFAULT_CONTACT](state, { projectId, contact }) {

    const curProjectIndex = state.projects.findIndex(c => c.id === projectId)

    const curProject = state.projects[curProjectIndex] ?? {}

    if (~curProjectIndex) {
      curProject.defaultContact = contact
    }

  },

  [TYPES.PROJECTS.UPDATE_PROJECT_CONTACT](state, { projectId, contact }) {

    const projectContacts = state.projectContacts[projectId] ?? [],
      contactIndex = projectContacts.findIndex(c => c.id === contact.id)

    if (~contactIndex) {
      const newProjectContacts = [...state.projectContacts[projectId]]
      newProjectContacts[contactIndex] = contact
      state.projectContacts[projectId] = newProjectContacts;
    }

  },

  [TYPES.PROJECTS.REMOVE_PROJECT_CONTACT](state, { projectId, contactId }) {
    const projectContacts = state.projectContacts[projectId] ?? [];

    const index = projectContacts.findIndex(c => c.id === contactId);
    if (index !== -1) {
      projectContacts.splice(index, 1);
    }
  },

  [TYPES.PROJECTS.SET_ADDING_CONTACT](state, { projectId, isAdding }) {
    state.addingContact[projectId] = isAdding;
  },

  [TYPES.PROJECTS.ADD_UPDATING_CONTACT](state, { projectId, contactId }) {
    let updatingContacts = state.updatingContacts[projectId];

    if (!updatingContacts) {
      state.updatingContacts[projectId] = [contactId];
    } else {
      const newUpdatingContacts = [
        ...updatingContacts.filter(c => c !== contactId),
        contactId,
      ];
      state.updatingContacts[projectId] = newUpdatingContacts;
    }
  },

  [TYPES.PROJECTS.REMOVE_UPDATING_CONTACT](state, { projectId, contactId }) {
    let updatingContacts = state.updatingContacts[projectId];

    if (updatingContacts) {
      const newUpdatingContacts = updatingContacts.filter(c => c !== contactId);
      state.updatingContacts[projectId] = newUpdatingContacts;
    }
  },

  [TYPES.PROJECTS.INIT_PROJECT_INTEGRATION](state, { projectId, integrationMapping }) {
    const mappings = state.integrationsByProjectIds[projectId];
    if (mappings) {
      const oldMapping = mappings.find(m => m.id === integrationMapping.id);

      if (oldMapping) {
        // patch all properties of the mapping
        Object.assign(oldMapping, integrationMapping);
      } else {
        mappings.push(integrationMapping);
      }
    } else {
      state.integrationsByProjectIds[projectId] = [integrationMapping];
    }
  },

  [TYPES.PROJECTS.DELETE_PROJECT_INTEGRATION_FOR_ALL](state, { integrationId }) {
    for (let mappings of Object.values(state.integrationsByProjectIds)) {
      const index = mappings.findIndex(
        ({ integration }) => integration.integrationId === integrationId
      );

      if (index > -1) {
        mappings.splice(index, 1);
      }
    }
  },

  [TYPES.PROJECTS.SET_REMOTE_FOLDER_LOADED](state, { remoteFolderId, loaded }) {
    state.remoteFolders.loaded[remoteFolderId] = loaded;
  },

  [TYPES.PROJECTS.SET_REMOTE_FOLDER_LOADING](state, { remoteFolderId, loading }) {
    state.remoteFolders.loading[remoteFolderId] = loading;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
