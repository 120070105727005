import FoldersProvider from '@provider/folders'
import * as TYPES from '@/store/types'

// initial state
const state = {
  folders: [],
}

// getters
const getters = {
  // getterFilesByChatId: state => ({id, limit=Infinity}) => {
  //   return state.files
  //     .filter(e => e.chatId == id)
  //     .sort((a, b) => b.id - a.id)
  //     .slice(0, limit);
  // }
  getterFoldersByProjectId: state => projectId => {
    return state.folders
      .filter(e => e.projectId == projectId && !e.chatId)
      .sort((a, b) => b.id - a.id)
  },
}

// actions
const actions = {
  downloadZipFolder({ commit }, { folder }) {
    return new Promise((resolve, reject) => {
      FoldersProvider.downloadZipFolder({
        folder,
        progress: percent => {
          commit(TYPES.FOLDERS.UPDATE_PERCENT_DOWNLOAD, {
            folderId: folder.id,
            percent,
          })
        },
      })
        .then(resolve)
        .catch(reject)
    })
  },

  addFolder({ commit }, { name, parentId, projectId, chatId, access, remoteFolderId }) {
    return new Promise((resolve, reject) => {
      FoldersProvider.addFolder({ name, parentId, projectId, chatId, access, remoteFolderId })
        .then(folder => {
          if (remoteFolderId && projectId) {
            folder.projectId = +projectId;
          }
          commit(TYPES.FOLDERS.INIT_FOLDERS, folder);
          resolve(folder)
        })
        .catch(reject)
    })
  },

  getFolder({ commit }, folderId) {
    return new Promise((resolve, reject) => {
      FoldersProvider.getFolder(folderId)
        .then(folder => {
          commit(TYPES.FOLDERS.INIT_FOLDERS, folder)
          resolve(folder)
        })
        .catch(reject)
    })
  },

  updateFolder({ commit }, { folderId, name, parentId, access }) {
    return new Promise((resolve, reject) => {
      FoldersProvider.updateFolder({ folderId, name, parentId, access })
        .then(folder => {
          commit(TYPES.FOLDERS.INIT_FOLDERS, folder)
          resolve(folder)
        })
        .catch(reject)
    })
  },

  updateRemoteFolder({ commit }, { remoteId, remoteFolderId, projectId, name, access }) {
    return FoldersProvider.updateRemoteFolder({
      remoteId,
      remoteFolderId,
      projectId,
      name,
      access,
    }).then(() => {
      commit(TYPES.FOLDERS.PATCH_FOLDER_PROPERTIES, {
        folderId: remoteId,
        parentId: remoteFolderId,
        name,
        access,
      });
    });
  },

  deleteFolder({ commit }, folderId) {
    return new Promise((resolve, reject) => {
      FoldersProvider.deleteFolder(folderId)
        .then(() => {
          commit(TYPES.FOLDERS.DELETE_FOLDERS, folderId)
          resolve()
        })
        .catch(reject)
    })
  },

  deleteRemoteFolder({ commit }, { remoteItemId, projectId }) {
    return FoldersProvider.deleteRemoteFolder({ remoteItemId, projectId }).then(() => {
      commit(TYPES.FOLDERS.DELETE_FOLDERS, remoteItemId);
    });
  },
}

// mutations
const mutations = {
  [TYPES.FOLDERS.INIT_FOLDERS](state, data) {
    let list = Array.isArray(data) ? data : [data],
      ids = list.map(e => e.id)

    state.folders = [
      ...state.folders.filter(e => !ids.includes(e.id)),
      ...list.filter(e => e.canView),
    ]
  },

  [TYPES.FOLDERS.DELETE_FOLDERS](state, data) {
    const ids = Array.isArray(data) ? data : [data]
    state.folders = [...state.folders.filter(e => !ids.includes(e.id))]
  },

  [TYPES.FOLDERS.DELETE_CHILD_FOLDERS](state, parentId) {
    const { folders } = state;
    const hasChildFolders = folders.some(f => f.parentId === parentId);
    if (!hasChildFolders) return;
    state.folders = folders.filter(f => f.parentId !== parentId);
  },

  [TYPES.FOLDERS.CHANGE_COUNT_FOLDERS](state, { id, position }) {
    const findF = state.folders.find(e => e.id == id)
    const pos = position ? 1 : -1
    if (!findF) return

    state.folders = [
      ...state.folders.filter(e => e.id != id),
      { ...findF, foldersCount: findF.foldersCount + pos },
    ]
  },

  [TYPES.FOLDERS.PATCH_FOLDER_PROPERTIES](state, { folderId, ...properties }) {
    const folder = state.folders.find(e => e.id === folderId);
    if (!folder) return;

    Object.entries(properties).forEach(([key, value]) => {
      if (value !== undefined) folder[key] = value;
    });
  },

  [TYPES.FOLDERS.UPDATE_PERCENT_DOWNLOAD](state, { folderId, percent }) {
    let folder = state.folders.find(e => e.id == folderId)
    if (!folder) return

    folder.percentDownload = percent
  },

  [TYPES.FOLDERS.CHANGE_COUNT_FILES](state, { id, position }) {
    const findF = state.folders.find(e => e.id == id)
    const pos = position ? 1 : -1
    if (!findF) return

    state.folders = [
      ...state.folders.filter(e => e.id != id),
      { ...findF, filesCount: findF.filesCount + pos },
    ]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
